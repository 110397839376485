import React, { useRef, useState } from 'react'
import { FlexboxGrid, Grid } from 'rsuite'
import { SelectionBox } from './SelectionBox'
export const SelectionBar = ({ models = [], onItemSelected, className, onRender }) => {

  const itemSelected = (model) => {
    if (onItemSelected === undefined) {
      return;
    }
    onItemSelected(model);
  }

  return (
    <div className={"rooms-selection-bar " + className}>
      {models.map((model, index) => {
        if (onRender !== undefined) {
          return <div onClick={() => itemSelected(model)}>{onRender(model)}</div>
        }
        return <SelectionBox model={model} onClick={itemSelected} />
      })}
    </div>)
}