import logo from './logo.svg';
import './App.css';
import { ThreeCanvas } from './components/ThreeCanvas'
import { Grid, Col, Row, IconButton, Icon, Button } from 'rsuite';
import { SelectionBar } from './components/SelectionBar';
import ModelFileDrawer from './components/Drawer/ModelFileDrawer';
import { useState } from 'react';
import { models } from './testData'
function App() {

  const [drawerModel, setDrawerModel] = useState();
  const [placedModels, setPlacedModels] = useState([]);

  const modelSelected = (model) => {
    console.log("Model", model);
    setDrawerModel(model);
  }


  const onVariantSelected = (model) => {
    console.log("Variant Selected", model)
    let oldPlacedModel = [...placedModels];
    oldPlacedModel.push(model);
    console.log(oldPlacedModel);
    setDrawerModel(undefined);
    setPlacedModels(oldPlacedModel)
  }
  return (<>
    <div className="App">

      <Grid className="contents">
        <Col lg={4}>
          <SelectionBar models={models} onItemSelected={modelSelected}></SelectionBar>
        </Col>
        <Col lg={20}>
          <Grid fluid>
            <div className="header">
              <h2>Modular Design Prototype</h2>
              <div>
                <IconButton size="lg" ripple appearance="primary" circle placement="right" icon={<Icon icon="print"></Icon>}></IconButton>
                <Button appearance="primary">VR Mode</Button>
              </div>
            </div>
            <ThreeCanvas models={placedModels}></ThreeCanvas>
          </Grid>
        </Col>
      </Grid>
    </div>

    <ModelFileDrawer model={drawerModel} onModelSelected={onVariantSelected}></ModelFileDrawer>
  </>);
}

export default App;
