import React, { useEffect, useState } from 'react';
import { Button, Drawer, List } from 'rsuite';
import { SelectionBar } from '../SelectionBar';
import { SelectionDrawerBox } from '../SelectionBox';
import ValuesList from './ValuesList';

const ModelFileDrawer = ({ model, onModelSelected }) => {

    const [localShow, setLocalShow] = useState(false);
    const [selectedModel, setSelectedModel] = useState();
    useEffect(() => {
        console.log("Showing Model", model)
        if (model === undefined)
            return;

        setLocalShow(true);
    }, [model]);

    useEffect(() => {

        setSelectedModel(undefined);
    }, [localShow]);

    if (model === undefined) {
        return <></>;
    }


    const onConfirm = () => {
        if (onModelSelected === undefined) {
            return;
        }
        onModelSelected(selectedModel);
        setSelectedModel(undefined);
        setLocalShow(false);
    }

    return (<Drawer size="md" placement="left" show={localShow} onHide={() => setLocalShow(false)}>
        <Drawer.Header >
            <Drawer.Title>{model.name}</Drawer.Title>
        </Drawer.Header>
        <Drawer.Body>

            <div className="drawer-description">{model.description}</div>

            <hr />
            <h5>Room Values</h5>
            <ValuesList values={model.values}></ValuesList>
            <hr />
            <h5>Variants</h5>
            <SelectionBar models={model.variants}
                className="building-variants-list"
                onRender={(model) => <SelectionDrawerBox model={model}></SelectionDrawerBox>} onItemSelected={(model) => {
                    setSelectedModel(model)
                }}></SelectionBar>
        </Drawer.Body>
        <Drawer.Footer >
            <Button appearance="primary" disabled={selectedModel === undefined} onClick={onConfirm}>Add to scene</Button>
            <Button appearance="subtle" onClick={() => setLocalShow(false)}>Cancel</Button>
        </Drawer.Footer>
    </Drawer >);
}

export default ModelFileDrawer;