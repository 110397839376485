export const models = [
    {
      name: "Room 001",
      description: "Room with ventilation",
      thumbnailImage: "/images/room1.png",
      values: [{
        key: "Width",
        value: "20m"
      },
      {
        key: "Length",
        value: "20m"
      },
      {
        key: "Height",
        value: "20m"
      },
    
      {
        key: "Base cost",
        value: "£20,000"
      }],
      variants: [{
        name: "Room 001, central ducting",
        description: "Room with ventilation",
        modelFile: "/models/room_1_0.fbx",
        thumbnailImage: "/images/room1_1.png",
        values: [
          {
            key: "Enviroment Rating",
            value: 0.2
          },
          {
            key: "Manufacturer",
            value: "EPC Modular buildings"
          },
          {
            key: "Cost",
            value: "£24,000"
          }
        ],
      },
      {
        name: "Room 002, vinyl flooring",
        description: "Room with ventilation and vinyl flooring",
        modelFile: "/models/room_1_2.fbx",
        thumbnailImage: "/images/room1_2.png",
        values: [{
          key: "Enviroment Rating",
          value: 0.1
        },
        {
          key: "Manufacturer",
          value: "EPC Modular buildings"
        },
        {
          key: "Cost",
          value: "£23,000"
        }
        ],
      }]
    },
    {
      name: "Sterile Room 001", description: "Room with ventilation", thumbnailImage: "/images/room3.png", values: [{
        key: "Width",
        value: "25m"
      },
      {
        key: "Length",
        value: "20m"
      },
      {
        key: "Height",
        value: "20m"
      }],
      variants: [{
        name: "Room 001, central ducting",
        modelFile: "/models/room_2_1.fbx",
        values: [
          {
            key: "Enviroment Rating",
            value: 0.2
          },
          {
            key: "Manufacturer",
            value: "EPC Modular buildings"
          },
          {
            key: "Cost",
            value: "£24,000"
          }
        ],
      },
      {
        name: "Room 002, vinyl flooring",
        modelFile: "/models/room_2_2.fbx",
        values: [{
          key: "Enviroment Rating",
          value: 0.1
        },
        {
          key: "Manufacturer",
          value: "EPC Modular buildings"
        },
        {
          key: "Cost",
          value: "£23,000"
        }
        ],
      }]
    },
    {
      name: "Surgical Suite", description: "Room with ventilation",
       thumbnailImage: "/images/room2.png", 
       values: [{
        key: "Width",
        value: "20m"
      },
      {
        key: "Length",
        value: "20m"
      },
      {
        key: "Height",
        value: "20m"
      }],
      variants: [{
        name: "Room 001, central ducting",
        modelFile: "/models/room_3_1.fbx",
        values: [
          {
            key: "Enviroment Rating",
            value: 0.2
          },
          {
            key: "Manufacturer",
            value: "EPC Modular buildings"
          },
          {
            key: "Cost",
            value: "£24,000"
          }
        ],
      },
      {
        name: "Room 002, vinyl flooring",
        modelFile: "/models/room_3_2.fbx",
        values: [{
          key: "Enviroment Rating",
          value: 0.1
        },
        {
          key: "Manufacturer",
          value: "EPC Modular buildings"
        },
        {
          key: "Cost",
          value: "£23,000"
        }
        ],
      }]
    },
  ]