import React, { useEffect, useState } from 'react';
import { Button, Drawer, List } from 'rsuite';

const ValuesList = ({ values = [], showHeader = true }) => {

    return (<List className="building-values">
        {showHeader === true ?
            <List.Item>
                <div className="building-value-row header">
                    <div className="building-value-key">Key</div>
                    <div className="building-value-data">Value</div>
                </div></List.Item> : <></>}

        <List.Item>
            {values.map((data) => {
                return (
                    <div className="building-value-row">
                        <div className="building-value-key">{data.key}</div>
                        <div className="building-value-data">{data.value}</div>


                    </div>
                )
            })}
        </List.Item>
    </List>)
}
export default ValuesList;