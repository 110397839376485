import React, { Suspense, useEffect, useRef, useState } from 'react';
import { Canvas, useFrame, useLoader } from '@react-three/fiber'
import { FBXLoader } from 'three-stdlib'
import { Box, TransformControls, useFBX } from '@react-three/drei';
import { Box3, Euler, MathUtils, Mesh, MeshStandardMaterial, Vector3 } from 'three';

const IFCObject = ({ fileLocation, controlRef, raycast, ...props }) => {
    const transformRef = useRef();
    const primitiveRef = useRef();
    const raycastRef = useRef();
    const [controlsEnabled, setControlsEnabled] = useState(false);
    const [fbx, setFbx] = useState();
    const [updateIntersection, setUpdateIntersection] = useState();
    const [objectMeshes, setObjectMeshes] = useState([]);
    const [boxObject, setBoxObject] = useState();

    useEffect(() => {
        if (transformRef.current) {
            const { current: controls } = transformRef

            const callback = (event) => (controlRef.current.enabled = !event.value)
            controls.addEventListener('dragging-changed', callback)
            return () => controls.removeEventListener('dragging-changed', callback)
        }
        //const raycastObject = raycast.intersectObjects(fbx);
        //console.log("RAY", raycastObject)
        console.log("RAY", raycast)
    })

    useEffect(() => {
        let loader = new FBXLoader();
        loader.load(fileLocation, (fbxObject) => {
            fbxObject.scale.set(0.01, 0.01, 0.01);
            fbxObject.setRotationFromEuler(new Euler(
                MathUtils.degToRad(270),
                MathUtils.degToRad(0),
                MathUtils.degToRad(180)));
            fbxObject.position.set(0, 0, 0);
            fbxObject.receiveShadow = true;
            let meshes = [];
            fbxObject.traverse(function (child) {
                if (child instanceof Mesh) {
                    child.geometry.computeVertexNormals()
                    // apply texture
                    //child.material = new MeshStandardMaterial();
                    child.receiveShadow = true;
                    child.material.needsUpdate = true;


                }
            });

            setObjectMeshes(meshes);
            setFbx(fbxObject);
            console.log("FBX OBject", fbxObject)

        });
    }, [fileLocation]);

    useEffect(() => {
        console.log("intersects", updateIntersection)
    }, [updateIntersection])


    if (fbx === undefined) {
        return <></>
    }
    return (

        <TransformControls
            translationSnap={1}
            showY={false}
            enabled={controlsEnabled}
            ref={transformRef}
            size={1}
            onPointerEnter={() =>setControlsEnabled(true)}
            onPointerLeave={() => setControlsEnabled(false || transformRef.current.dragging)}
            onPointerCancel={() => setControlsEnabled(false)}
            
            isTransformControls={true}>

            <primitive key={fileLocation} {...props} object={fbx} >
            </primitive>

        </TransformControls>
    )
}

export default IFCObject