import React, { useRef, useState } from 'react'
import { FlexboxGrid } from 'rsuite'
import ValuesList from './Drawer/ValuesList'
export const SelectionBox = ({ model, onClick }) => {

  return (
    <div className="rooms-selection" onClick={() => onClick(model)}>
      <img src={model.thumbnailImage}></img>
      <div>{model.name}</div>
    </div>)
}

export const SelectionDrawerBox = ({ model, onClick }) => {

  return (
    <div className="rooms-selection">
      <div className="rooms-data">
        <h6 className="name">{model.name}</h6>
        <ValuesList values={model.values} showHeader={false} ></ValuesList>
      </div>
      <img src={model.thumbnailImage}></img>
    </div>)
}